import {User} from "../user.model";

export class BillingHolder {

  billingStatsList: {month:'', totalBillable: 0, totalOffDuty: 0, billed: 0} [];
  user: User;
  selectedYear: String;
  displayDays: boolean;

  constructor(messageJSON: any) {
    this.billingStatsList = messageJSON.billingStatsList;
    this.user = messageJSON.user;
    this.selectedYear = messageJSON.selectedYear;
    this.displayDays = messageJSON.displayDays;
  }
}
