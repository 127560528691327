import {Component, OnInit} from '@angular/core';
import {ToolsBoxService} from './Services/tools.service';

import {Router, NavigationEnd} from '@angular/router';
import {map} from 'rxjs/operators'
import {Observable} from "rxjs";
import {NotificationService} from "./Services/notification.service";

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  //If a childComponent contains tabs, a style is added to the "body-content" div
  childCompContainsTabs$: Observable<boolean>;
  //If a childComponent raises an action, this boolean will set to true
  //displaying the waiter
  actionInProgress$: Observable<boolean>;
  message: Observable<string>;
  type: Observable<string>;

  constructor(private router: Router, private tools: ToolsBoxService, private readonly notificationService: NotificationService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && document.getElementById('body-content') != null) {
        document.getElementById('body-content').scrollTop = 0;
      }
    });
  }

  ngOnInit() {
    let childCompContainsTabs$ = this.tools.getEmittedContainsTabsValue()
      .pipe(map((item: boolean) => item));
    let actionInProgress$ = this.tools.getEmittedActionInProgressValue()
      .pipe(map((item: boolean) => item));

    if (this.tools.checkUserisLoggedIn()) {
      this.router.navigate(['Dashboard']);
    }

    this.message = this.notificationService.message;
    this.type = this.notificationService.type;
  }

  isLoggedin() {
    return this.tools.checkUserisLoggedIn();
  }

  hideNotification() {
    this.notificationService.setMessage(null);
  }
}
