import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../../Models/user.model";
import {UserService} from "../../../../Services/user.service";
import {NotificationService} from "../../../../Services/notification.service";
import {allControlsValidValidator, CreationItzModel, loginFormatValidator} from "../../../../Models/creation-itz.model";

@Component({
  selector: 'user-information-tab',
  templateUrl: './user-information-tab.component.html',
  styleUrls: ['./user-information-tab.component.css']
})
export class UserInformationTabComponent implements OnChanges, OnInit {

  @Input() user: User;  // Input property to receive user data
  @Output() userUpdated: EventEmitter<User> = new EventEmitter<User>();  // Output event emitter to send updated user
                                                                         // data
  @Output() resetPassword: EventEmitter<void> = new EventEmitter<void>();  // Output event emitter to signal password
                                                                           // reset

  form: FormGroup<CreationItzModel>; // Reactive form group to handle user form

  constructor(private fb: FormBuilder, private userService: UserService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user'] && this.user) {
      this.updateFormValues();  // Update form values when user input changes
    }
  }

  initializeForm() {
    this.form =
      new FormGroup<CreationItzModel>({
        firstName: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
        lastName: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
        userLogin: new FormControl({value: '', disabled: true}, {
          nonNullable: true,
          validators: [Validators.required, loginFormatValidator()]
        }),
        userMailAddress: new FormControl({value: '', disabled: true}, {
          nonNullable: true,
          validators: [Validators.required, Validators.email]
        }),
        userRecordID: new FormControl(''),
        userClientName: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
        isBlocked: new FormControl(false, {nonNullable: true, validators: [Validators.required]}),
        isAdmin: new FormControl(false, {nonNullable: true, validators: [Validators.required]})
      }, {
        validators: allControlsValidValidator()
      })
    ;

    if (this.user) {
      // Initialize form with user data if available
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.form) {
      this.form.patchValue({
        isAdmin: this.user.userProfile === 'ADMIN',
        firstName: this.user.userFirstName,
        lastName: this.user.userName,
        userLogin: this.user.userLogin,
        userMailAddress: this.user.userMailAddress,
        userRecordID: this.user.recordId,
        userClientName: this.user.clientName,
        isBlocked: this.user.userStatus === 'INACTIVE'
      });
    }
  }

  switchUserProfile() {
    if (this.user) {
      this.form.controls['isAdmin'].patchValue(!this.form.controls['isAdmin'].value);
      this.updateUser();
    }
  }

  switchUserStatus() {
    if (this.user) {
      this.form.controls['isBlocked'].patchValue(!this.form.controls.isBlocked.value);
      this.updateUser();
    }
  }

  itzInitialisation() {
    if (this.form) {
      const firstName = this.form.controls.firstName.value || '';
      const lastName = this.form.controls.lastName.value || '';
      this.form.controls.userMailAddress.patchValue(`${firstName.toLowerCase()}.${lastName.toLowerCase()}@xpertiz.lu`);
      this.form.controls.userLogin.patchValue(`${firstName.toLowerCase()}.${lastName.toLowerCase()}`);
      this.updateUser();
    }
  }

  resetUserPwd() {
    this.resetPassword.emit();
  }

  updateUser() {
    if (this.user && this.form) {
      this.user.userFirstName = this.form.controls.firstName.value;
      this.user.userName = this.form.controls.lastName.value;
      this.user.userLogin = this.form.controls.userLogin.value;
      this.user.userMailAddress = this.form.controls.userMailAddress.value;
      this.user.clientName = this.form.controls.userClientName.value || 'BENCH';
      this.user.recordId = this.form.controls.userRecordID.value || '';
      this.user.userProfile = this.form.controls.isAdmin.value ? 'ADMIN' : 'EMPLOYEE';
      this.user.userStatus = this.form.controls.isBlocked.value ? 'INACTIVE' : 'ACTIVE';

      // Check if balances is null and initialize if necessary
      if (this.user.balances == null) {
        this.user.balances = [];
      }
    }
  }

  updateOrNewUser() {
    if (this.form.status === "VALID" && this.user) {
      this.form.markAsUntouched();
      this.userUpdated.emit(this.user);

    }
  }

  private controlHasErrorAndTouched(controlName: keyof CreationItzModel, errorType: string): boolean {
    const control = this.form.controls[controlName];
    return !!control?.errors?.[errorType] && control.touched;
  }

  private controlIsRequiredErrorAndTouched(controlName: keyof CreationItzModel): boolean {
    return this.controlHasErrorAndTouched(controlName, 'required');
  }

  isFirstNameRequiredError(): boolean {
    return this.controlIsRequiredErrorAndTouched('firstName');
  }

  isLastNameRequiredError(): boolean {
    return this.controlIsRequiredErrorAndTouched('lastName');
  }

  isUserLoginRequiredError(): boolean {
    return this.controlIsRequiredErrorAndTouched('userLogin');
  }

  isUserMailAddressRequiredError(): boolean {
    return this.controlIsRequiredErrorAndTouched('userMailAddress');
  }

  isUserClientNameRequiredError(): boolean {
    return this.controlIsRequiredErrorAndTouched('userClientName');
  }

  isUserMailAddressFormatError(): boolean {
    return this.controlHasErrorAndTouched('userMailAddress', 'email');
  }

  isUserLoginFormatError(): boolean {
    return this.controlHasErrorAndTouched('userLogin', 'invalidLoginFormat');
  }
}
