import {
  Component,
  OnInit
} from '@angular/core';
import {ToolsBoxService} from "../../Services/tools.service";
import {Router} from "@angular/router";
import {UserService} from "../../Services/user.service";
import {User} from "../../Models/user.model";
import {BlipsData, SkillData, StatData, TechRadarDataHolder} from "../../Models/Holders/techRadarDataHolder.model";
import {SelectItem} from "primeng/api";



@Component({
  selector: 'techRadar',
  templateUrl: 'techRadar.component.html',
  styleUrls: ['techRadar.component.css']
})
export class TechRadarComponent implements OnInit {

  displaySurvey: boolean = true;
  boolValues: SelectItem[] = [
      {label: 'Let\'s compose your Tech radar', value: true},
      {label: 'Display your Tech radar', value: false}
    ];
  skillCategories: SelectItem[] = [
    {label: 'LANGUAGES AND FRAMEWORKS', value: 'LANGUAGES'},
    {label: 'TOOLS', value: 'TOOLS'},
    {label: 'PLATFORMS', value: 'PLATFORMS'},
    {label: 'METHODOLOGIES', value: 'METHODOLOGIES'}
  ];
  selectedTab: string = 'LANGUAGES'

  languagesSkills: SkillData[] = [] as SkillData[];
  toolsSkills: SkillData[] = [];
  platformsSkills: SkillData[] = [];
  methodologiesSkills: SkillData[] = [];
  techRadarData: BlipsData[] = [];
  techRadarStats: StatData[] = [];

  //Notification
  showAlert: boolean = false;
  notifType: String;
  notifMessage: String;

  //Manager
  users: User[];
  selectedUser: User = null;

  draftMode: boolean = false;

  constructor(private userService: UserService,
              private toolsBoxService: ToolsBoxService,
              private router: Router) {
  }

  ngOnInit() {
    this.toolsBoxService.changeHeaderName("TechRadar");
    if (this.isCoordinator()) {
      this.userService.getUserList().subscribe(
        (list: User[]) => {
          this.users = list;
          this.selectedUser = this.users.find(u => u.userLogin === this.toolsBoxService.getStoredUserLogin());
          this.toolsBoxService.tellActionIsInProgress(false);
        },
        (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(["Error", {errMessage: err.error}]);
        }
      );
      this.userService.getTechRadaStat().subscribe(
        (list: StatData[]) => {
          this.techRadarStats = list;
        },
        (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(["Error", {errMessage: err.error}]);
        }
      );


      this.displaySurvey = false;
    }

    this.getDataFromBack(null);
  }

  handleChange = (element: any) => {
    this.getDataFromBack(this.selectedUser);
  }

  //Tabs are only displayed if the current user has EVE?T_MANAGER profile
  isCoordinator = (): boolean => {
    return this.toolsBoxService.isEventManager();
  }


  //initialization of data
  getDataFromBack = (itz: User) => {
    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getTechRadarData(itz).subscribe(
      (techRadarData: TechRadarDataHolder) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.languagesSkills = [];
        this.toolsSkills = [];
        this.platformsSkills = [];
        this.methodologiesSkills = [];
        techRadarData.skills
          .map(skill => {
          switch (skill.quadrant) {
            case "LANGUAGES":
              this.languagesSkills.unshift(skill);
              break;
            case "TOOLS":
              this.toolsSkills.unshift(skill);
              break;
            case "PLATFORMS":
              this.platformsSkills.unshift(skill);
              break;
            case "METHODOLOGIES":
              this.methodologiesSkills.unshift(skill);
              break;

          }
        } );
        this.techRadarData = techRadarData.techRadarData
          .filter(data => data.ring >= 0);
      },
      (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    );
  }

  saveSkills = () => {
    let data: TechRadarDataHolder = new TechRadarDataHolder({
      user : {},
      skills: this.languagesSkills.concat(this.methodologiesSkills, this.platformsSkills, this.toolsSkills),
      techRadarData: []
    });

      this.userService.updateSkills(data).subscribe(
        (result: boolean) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.draftMode = false;
          this.notifMessage = 'Your selection has been saved';
          this.notifType = 'info';
          this.showAlert = true;
          this.getDataFromBack(null);
        },
        (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(["Error", {errMessage: err.error}]);
        }
      );
  }
}
