import {Component, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToolsBoxService} from '../../Services/tools.service';
import {UserService} from '../../Services/user.service';
import {User} from '../../Models/user.model';
import {SearchService} from '../../Services/search.service';
import {NotificationService} from "../../Services/notification.service";
import {catchError, tap} from "rxjs/operators";
import {of} from "rxjs";

@Component({
  selector: 'usersAdministration',
  templateUrl: 'usersAdministration.component.html',
  styleUrls: ['usersAdministration.component.css']
})

export class UsersAdministration implements OnInit {

  showResetModal = false; // Flag to show/hide the reset modal
  isInfoPanelOpen = true;

  // Itz list displayed
  $itzList: User[] = [];
  displayInactiveUser = false; // Flag to show/hide inactive users

  // Data that will be edited
  selectedUser: User = new User();

  // Filter
  itzFilter = "";

  private readonly toolsBoxService: ToolsBoxService = inject(ToolsBoxService);
  private readonly userService: UserService = inject(UserService);
  private readonly router: Router = inject(Router);
  private readonly searchService: SearchService = inject(SearchService);
  private readonly notificationService: NotificationService = inject(NotificationService);

  ngOnInit() {
    this.toolsBoxService.changeHeaderName("Users Administration");
    this.initItzList();
  }

  // Initialize the Itz list
  initItzList = () => {
    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getItzList().subscribe({
      next: (list: User[]) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.$itzList = list;
      },
      error: err => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    });
  }

  // Initialize the input form with user information
  initInformationInputForm = (itz?: User) => {
    this.selectedUser = {...itz};
    this.isInfoPanelOpen = true;
  }

  // Set background color based on user status
  setBackgroundColor(itz: User): string {
    return itz.userStatus === 'INACTIVE' ? '#ffebee' : 'inherit';
  }

  // Handle user updates from the child component
  onUserUpdated(updatedUser: User) {
    this.userService.createITz(updatedUser).pipe(
      tap(message => {
        this.notificationService.setType("info");
        this.notificationService.setMessage(message);
        this.selectedUser = updatedUser;
      }),
      catchError(err => {
        this.notificationService.setType("error");
        this.notificationService.setMessage(err?.message || "Unknown error");
        return of(null);
      })).subscribe();

  }

  // Reset the filter
  resetFilter = (): void => {
    this.itzFilter = "";
    this.search();
  }

  shouldHideUser(itz: User): boolean {
    if (this.displayInactiveUser) {
      return false;
    } else {
      return itz.userStatus === 'INACTIVE';
    }
  }

  search = (): void => {
    this.$itzList = this.searchService.search(this.$itzList, this.itzFilter);
  }


}
