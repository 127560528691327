import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";

@Injectable(
  {providedIn: "root"}
)
export class NotificationService {

  private message$ = new BehaviorSubject<string | null>(null);
  private type$ = new BehaviorSubject<string | null>(null);
  message = this.message$.asObservable();
  type = this.type$.asObservable();

  public setMessage(message: string): void {
    this.message$.next(message);
  }

  public setType(type: string): void {
    this.type$.next(type);
  }
}
