import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { UserService } from '../../Services/user.service';
import { ToolsBoxService } from '../../Services/tools.service';
import {FirstLetterPipe} from "../../Pipes/firstletter.pipe";
import {User} from "../../Models/user.model";

@Component({
    selector: 'settings',
    templateUrl: 'settings.component.html',
    styleUrls: ['settings.component.css']
})
export class SettingsComponent implements OnInit {
    currentUser: User;
    newPassword: String = '';
    confirmNewPassword: String = '';
    showAlert: boolean = false;

    //Notification
    notifType: String;
    notifMessage: String;

    constructor(private userService: UserService, private toolsBoxService: ToolsBoxService,
        private router: Router) {
      this.currentUser = new User(Number(this.toolsBoxService.getStoredUserId()));
    }

    ngOnInit() {
        this.toolsBoxService.changeHeaderName("Profile settings");
        this.toolsBoxService.tellChildContainsTabs(false);
        this.retrieveUserInfo();
    }

    retrieveUserInfo = () => {
        this.toolsBoxService.tellActionIsInProgress(true);
        var $returnSubs = this.userService.getUserInformation(this.currentUser).subscribe(
            (data) => {
                this.currentUser = data;
                this.toolsBoxService.tellActionIsInProgress(false);
            },
            (err) => {
                this.toolsBoxService.tellActionIsInProgress(false);
                this.router.navigate(["Error", {errMessage: err.message }]);
            }
        );
    }

    updateData = () => {
        this.toolsBoxService.tellActionIsInProgress(true);
        var $returnSubs = this.userService.updateUserInformation(this.currentUser, this.newPassword).subscribe(
            () => {
                this.toolsBoxService.tellActionIsInProgress(false);
                this.notifMessage = 'Your account has been updated';
                this.notifType = 'info';
                this.showAlert = true;
                this.toolsBoxService.changeAvatar(this.currentUser.avatar);
            },
            (err) => {
                this.toolsBoxService.tellActionIsInProgress(false);
                this.router.navigate(["Error", {errMessage: err.message }]);
            }
        )
    }

    cancel = () => {
        this.retrieveUserInfo();
        this.confirmNewPassword = '';
        this.newPassword = '';
        this.notifMessage = 'Your changes have been discarded';
        this.notifType = 'warning';
        this.showAlert = true;

    }

  getStyle = (userName: string) : String => {
    let firstLetterPipe = new FirstLetterPipe();
    return "user-first-initial-" + firstLetterPipe.transform(userName);
  }

  removeAvatar = () => {
      this.currentUser.avatar = null;
  }
  /**
   * Get the selected file and check its size
   * @param event
   */
  onChangeAvatar = (event: any) => {
      let files: File[];
      let target = event.target || event.srcElement;
      files = target.files;
      let currentFile : File  = files[0];

      if (currentFile.size > 60000){
        this.notifType = 'error';
        this.notifMessage = 'The file should size less than 60Kb';
        this.showAlert = true;
      }else {
        this.checkFile(currentFile);
      }
  }

  checkFile = (file: File) =>{
      let fileReader = new FileReader();
      //Define the onLoad action
      fileReader.onloadend = () => {

        let imageBase64 = fileReader.result;
        //Check file type: depending on the file extension
        if (!imageBase64.toString().startsWith("data:image")) {
            this.notifMessage = 'Wrong file type';
            this.notifType = 'error';
            this.showAlert = true;
        } else {
          //Check picture size
          let img = new Image();
          //Define onLoad action
          img.onload = () => {
            this.currentUser.avatar = imageBase64.toString();
          }
          //Load the file in order to trigger the load action
          img.src = imageBase64.toString();
        }
      }
      //Load the file as a DataURL
      fileReader.readAsDataURL(file);
  }
}
