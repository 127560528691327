/**
 * Created by Xpertiz on 21/10/2016.
 */

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CalendarEventHolder } from '../Models/Holders/calendareventholder.model';

@Component({
  selector: 'notification-modal',
  templateUrl: 'notificationmodal.component.html'
})

export class NotificationModalComponent {

  @Input('title') modalTitle: String;
  @Input('detail') currentDetail: CalendarEventHolder[];
  @Input('type') type: String;

  @Output('onClose') onClose: EventEmitter<any> = new EventEmitter();

  constructor() {}

  closeModale() {
    this.onClose.next(true);
  }
}
