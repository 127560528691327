import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from "../../../../Models/user.model";
import {Balance} from "../../../../Models/balance.model";
import {CreationBalanceModel} from "../../../../Models/creation-balance.model";

@Component({
  selector: 'user-balance-tab',
  templateUrl: './balance-tab.component.html',
  styleUrls: ['./balance-tab.component.css']
})
export class BalanceTabComponent implements OnChanges {
  @Input() user: User;
  @Output() onUserUpdated: EventEmitter<User> = new EventEmitter<User>();

  balanceTypes: string[] = ['LHOL', 'EHOL'];
  showNewLine = false;

  form: FormGroup<CreationBalanceModel>;
  balanceIdToUpdate = -1;
  balanceIdToDelete = -1;

  constructor() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user'] && !changes['user'].isFirstChange()) {
      this.showNewLine = false;
      this.createForm();
    }
  }

  createForm() {
    this.form = new FormGroup<CreationBalanceModel>({
      balanceId: new FormControl(0, {validators: [Validators.required]}),
      balanceUsr: new FormControl(new User(), {nonNullable: true, validators: [Validators.required]}),
      eventType: new FormControl('LHOL', {nonNullable: true, validators: [Validators.required]}),
      balanceYear: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
      balanceDesc: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
      balance: new FormControl(0, {nonNullable: true, validators: [Validators.required]}),
      status: new FormControl(''),
    });

  }

  openModal(index: number) {
    this.balanceIdToDelete = index;
  }

  confirmationDeleteBalance(position: number) {
    this.user.balances[position].status = "DELETED";
    this.onUserUpdated.next(this.user);
  }

  setBackgroundBalanceColor(balance: Balance, selectedBalanceId: number) {
    return balance.balanceId === selectedBalanceId ? '#90E0EF' : '#FFFFFF';
  }

  setFontColor(balance: Balance) {
    return balance.status === 'NEW' ? '#566D7E' : balance.status === 'DELETED' ? '#1520A6' : '#000000';
  }

  displayImg(balance: Balance) {
    return balance.status === 'DELETED' ? 'css/images/untrash.png' : 'css/images/trash.png';
  }

  mapBalanceFromForm(): Balance {
    return {
      balanceId: this.form.controls.balanceId.value,
      balanceUsr: this.form.controls.balanceUsr.value,
      eventType: this.form.controls.eventType.value,
      balanceYear: this.form.controls.balanceYear.value,
      balanceDesc: this.form.controls.balanceDesc.value,
      balance: this.form.controls.balance.value,
      status: 'NEW',
      editing: false
    } as Balance;
  }

  openNewLine() {
    if (!this.showNewLine) {
      this.showNewLine = true;
      this.user.balances[this.balanceIdToUpdate] = {...this.user.balances[this.balanceIdToUpdate], editing: false};
    }
  }

  closeNewLine() {
    this.showNewLine = false;
    this.balanceIdToUpdate = -1;
  }

  saveNewBalance() {
    const newBalance: Balance = this.mapBalanceFromForm();
    newBalance.status = 'NEW';
    this.user.balances.push(newBalance);
    this.createForm();
    this.closeNewLine();
    this.onUserUpdated.next(this.user);
  }

  cancelNewBalance() {
    this.createForm();
    this.closeNewLine();
  }

  editBalance(balance: Balance, position: number) {
    balance.editing = true;
    this.showNewLine = false;
    this.closeEditionIfAlreadyOpen();
    this.mapFormFromBalance(balance);
    this.balanceIdToUpdate = position;
  }

  private closeEditionIfAlreadyOpen() {
    if (this.balanceIdToUpdate > -1) {
      this.cancelEdit(this.user.balances[this.balanceIdToUpdate]); // 1 seul formulaire de modification d'activé
    }
  }

  updateBalanceAtPosition(balance: Balance, position: number) {
    if (this.form.status === 'VALID') {
      balance.editing = false;
      this.user.balances[position] = {
        ...this.user.balances[position],
        balanceYear: this.form.controls.balanceYear.value,
        eventType: this.form.controls.eventType.value,
        balance: this.form.controls.balance.value,
        balanceDesc: this.form.controls.balanceDesc.value,
      };
      this.onUserUpdated.next(this.user);
      this.balanceIdToUpdate = -1;
    }
  }

  cancelEdit(balance: Balance) {
    balance.editing = false;
    this.balanceIdToUpdate = -1;
  }

  mapFormFromBalance(balance: Balance) {
    this.form.patchValue(balance);
  }

  showNoResult(): boolean {
    return this.user?.balances?.length === 0 && !this.showNewLine;
  }

  reinitModal() {
    this.balanceIdToDelete = -1;
  }
}
