import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../Services/user.service';
import {ToolsBoxService} from '../../Services/tools.service';
import {User} from '../../Models/user.model';

@Component({
  selector: 'overview',
  templateUrl: 'overview.component.html',
  styleUrls: ['overview.component.css']
})
export class OverviewComponent implements OnInit {
  constructor(private userService: UserService, private toolsBoxService: ToolsBoxService, private router: Router) {
  }

  users: User[];
  selectedUser: User;
  @Input() displayedAsTab: boolean = false;

  displayDashboard: boolean = true;

  //Event label
  eventTypeLabels: any;

  ngOnInit() {
    this.toolsBoxService.tellChildContainsTabs(true);
    this.toolsBoxService.tellActionIsInProgress(true);
    this.eventTypeLabels = this.toolsBoxService.eventLabels;
    // console.log('Overview - onInit=> getUserList');
    this.userService.getTeamList().subscribe(
      (list: User[]) => {
        this.users = list;
        if (this.users.length > 0)
          this.selectedUser = this.users[0];
        this.toolsBoxService.tellActionIsInProgress(false);
      },
      (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    );
  }
}
