import {NgModule} from '@angular/core';
import {
  UserInformationTabComponent
} from "../Components/UsersAdministration/tabs/user-information-tab/user-information-tab.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BalanceTabComponent} from "../Components/UsersAdministration/tabs/balance-tab/balance-tab.component";
import {ResetPasswordModalComponent} from "../Modals/resetpwdmodal.component";
import {TrigrammePipe} from "../Pipes/trigramme.pipe";


@NgModule({
  declarations: [UserInformationTabComponent, BalanceTabComponent, ResetPasswordModalComponent, TrigrammePipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    UserInformationTabComponent,
    BalanceTabComponent,
    ResetPasswordModalComponent,
    TrigrammePipe
  ]
})
export class SharedModule {
}
