import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './Components/Login/login.component';
import {LogoutComponent} from './Components/Logout/logout.component';
import {TimeManagement} from './Components/TimeManagement/timemanagement.component';
import {DirectoryComponent} from './Components/Directory/directory.component';
import {SettingsComponent} from './Components/Settings/settings.component';
import {ErrorComponent} from './Components/Error/error.component';
import {DashboardComponent} from './Components/Dashboard/dashboard.component';
import {TSCheckComponent} from "./Components/TSCheck/tscheck.component";
import {ReleaseNoteComponent} from "./Components/ReleaseNote/releasenote.component";
import {Planning} from "./Components/Planning/planning.component";
import {UsersAdministration} from "./Components/UsersAdministration/usersAdministration.component";
import {Tutorials} from "./Components/Tutorials/tutorials.component";
import {ScheduleComponent} from "./Components/Schedule/schedule.component";
import {TechRadarComponent} from "./Components/TechRadarComponent/techRadar.component";
import {EventManagement} from './Components/EventManagement/eventmanagement.component';


const routes: Routes = [
  {
    path: 'EventManagement',
    component: EventManagement
  }, {
    path: 'TimeManagement',
    component: TimeManagement
  }, {
    path: 'Directory',
    component: DirectoryComponent
  }, {
    path: 'Error',
    component: ErrorComponent
  }, {
    path: 'Settings',
    component: SettingsComponent
  }, {
    path: 'Dashboard',
    component: DashboardComponent
  }, {
    path: 'Logout',
    component: LogoutComponent
  }, {
    path: 'Login',
    component: LoginComponent
  }, {
    path: 'Planning',
    component: Planning
  }, {
    path: 'Planning/:eventId',
    component: Planning
  }, {
    path: 'TSCheck',
    component: TSCheckComponent
  }, {
    path: 'UsersAdministration',
    loadChildren: () => import('./Components/UsersAdministration/usersAdministration.module').then(m => m.UsersAdministrationModule)
  }, {
    path: 'Tutorials',
    component: Tutorials
  }, {
    path: 'ReleaseNote',
    component: ReleaseNoteComponent
  }, {
    path: 'Schedule',
    component: ScheduleComponent
  }, {
    path: 'TechRadar',
    component: TechRadarComponent
  },
  {
    path: '',
    redirectTo: 'Login',
    pathMatch: 'full'
  }
];

export const routing = RouterModule.forRoot(routes, {useHash: true});
