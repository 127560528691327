import { Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ToolsBoxService } from '../../Services/tools.service';
import { UserService } from '../../Services/user.service';
import {PlanningItem} from "../../Models/planningitem.model";
import {CalendarEventHolder} from "../../Models/Holders/calendareventholder.model";
import {User} from "../../Models/user.model";
import {SelectItem} from "primeng/api";
import { SearchService } from '../../Services/search.service';

@Component({
  styleUrls: ['planning.component.css'],
  selector: 'planning',
  templateUrl: 'planning.component.html',

})

export class Planning implements OnInit {

  employees: User[];
  users: User[];
  planningItems: PlanningItem[];
  draftEvent: CalendarEventHolder = new CalendarEventHolder();
  draftAttendeesList: User[] = [];
  attendeesList: User[] = [];
  currentEvent: PlanningItem = new PlanningItem();
  routeEventIndex: number = 0;
  userFilter: string;
  editionMode: boolean = false;
  editButtonLabel: string = "EDIT EVENT";

  localInfo: any = {
    firstDayOfWeek: 1,
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: 'Today',
    clear: 'Clear'
  };
  periods: SelectItem[] = [{label: 'AM', value: 'AM'}, {label: 'PM', value: 'PM'}, {label: 'AW', value: 'AW'}];

  //Notification
  notifType: String;
  notifMessage: String;
  showAlert: boolean = false;

  constructor(private toolsBoxService: ToolsBoxService,
              private searchService: SearchService,
              private userService: UserService,
              private route: ActivatedRoute,
              private router: Router) {
    this.route.params.subscribe( params => {
      this.routeEventIndex = (params["eventId"]?params["eventId"]:0);
    });

  }
  ngOnInit() {
    this.toolsBoxService.tellActionIsInProgress(true);
    let $returnSubs = this.userService.getSocialEventPlanning().subscribe(
      (data) => {
        this.planningItems = data;
        this.toolsBoxService.tellActionIsInProgress(false);
        if (this.planningItems.length > 0) {
          this.currentEvent = Object.assign({}, this.planningItems[0]);
          this.planningItems.map((event, index) => {
            if (event.event.socialEventId===Number(this.routeEventIndex)) {
              this.currentEvent = Object.assign({}, this.planningItems[index]);
            }
          })
        }
      },
      (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", { errMessage: err.message }]);
      }
    )

    this.userService.getUserList().subscribe(
      (list: User[]) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.employees = list;
      },
      (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    );
    this.toolsBoxService.changeHeaderName("Planning");
  }


  onChange(item: PlanningItem) {
    this.currentEvent = Object.assign({},item);
    this.editionMode = false;

  }

  getHeaderClass = (item: PlanningItem) : string => {
    return "list-group-item label-spacing btn btn-modal-event-" + item.event.eventType.toLowerCase();
  }

  /* answer invitations */
  handleParticipationResponse = (currentEvent: CalendarEventHolder, willParticipate: boolean) => {
    currentEvent.invitationStatus = (willParticipate?"ACCEPTED":"REFUSED");
    this.toolsBoxService.tellActionIsInProgress(true);
    let result = this.userService.handleEventInvitation(currentEvent).subscribe(
      (data) => {
        data.map(element => {
          if (element.event.eventId === this.currentEvent.event.eventId){
            Object.assign(this.currentEvent, element);
          }
        })
        this.toolsBoxService.tellActionIsInProgress(false);
      },
      (err) => {
        if (err) {
          this.router.navigate(["Error", {errMessage: err.error}]);
        }

        this.toolsBoxService.tellActionIsInProgress(false);
      })
    ;
  }

  isEventManager = () =>  {
    return (this.toolsBoxService.isEventManager());
  }


  /**
   * Add users in selectedList
   * @param user
   */
  addItzInList(user: User) {
      let index = this.attendeesList.findIndex((element) => user.id === element.id);
      if (index === -1) {
        this.draftAttendeesList.push(user);
        this.attendeesList.push(user)
        let indexInEmployee = this.users.findIndex((element) => user.id === element.id);
        this.users.splice(indexInEmployee, 1);
      }
  }

  /**
   * Add users in selectedList
   * @param user
   */
   removeItzInList(user: User) {
    let index = this.draftAttendeesList.findIndex((element) => user.id === element.id);
    this.draftAttendeesList.splice(index, 1);
    index = this.attendeesList.findIndex((element) =>user.id === element.id);
    this.attendeesList.splice(index, 1);
    this.users.push(user);
  }

  isNewJoiner = (user: User) : boolean =>  {
     return this.draftAttendeesList.includes(user);
  }

  search = (): void => {
    this.users = this.searchService.search(this.users, this.userFilter);
  }

  saveChanges() {
    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.sendUserEvent(this.draftEvent).subscribe({
      next : () => {
        this.switchEditionMode();
        this.toolsBoxService.tellActionIsInProgress(false);
        this.ngOnInit();
      },
      error : (err) => {
        console.error('Failed to save changes:', err);
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(['Error', { errMessage: err.message }]);
      }
    });
  }

  cancelEvent(): void {
    this.draftEvent.eventStatus = 'DEL_REQUESTED';
    this.userService.sendUserEvent(this.draftEvent).subscribe({
      next : () => {
        this.switchEditionMode();
        this.toolsBoxService.tellActionIsInProgress(false);
        this.ngOnInit();
      },
      error : (err) => {
        console.error('Failed to save changes:', err);
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(['Error', { errMessage: err.message }]);
      }
    });
  }

  switchEditionMode = () =>  {
    this.editionMode = !this.editionMode;
    if (this.editionMode){
      this.editButtonLabel = "CANCEL";
      this.users = [];
      this.draftAttendeesList =  [];
      this.attendeesList = Object.assign([], [...this.currentEvent.agreeList, ...this.currentEvent.refuseList, ...this.currentEvent.pendingList]);
      this.draftEvent = Object.assign({}, this.currentEvent.event);
      this.employees.forEach(employee => {
        if (this.attendeesList.findIndex((element) => employee.id === element.id) === -1) {
          this.users.push(employee);
        }
      })
    } else {
      this.editButtonLabel = "EDIT EVENT";
      this.draftEvent = new CalendarEventHolder();
      this.attendeesList =  [];
      this.draftAttendeesList =  [];
    }
  }

  resetFilter = (): void => {
    this.userFilter = "";

    this.search();
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'DELETED':
      case 'REFUSED':
      case 'CANCEL':
        return 'text-danger';
      case 'NEW':
        return 'text-primary';
      case 'PENDING':
        return 'text-warning';
      case 'VALIDATED':
      case 'CORRECTED':
        return 'text-success';
      case 'RESTORE':
        return 'text-warning-restore';
      default:
        return '';
    }
  }
}

