import {Component, Input, OnInit, OnChanges, ViewChild} from '@angular/core';
import {ToolsBoxService} from '../../Services/tools.service';
import {CalendarEvent} from "../../Models/calendarevent.model";
import {CalendarEventHolder} from "../../Models/Holders/calendareventholder.model";
import {User} from "../../Models/user.model";
import {UserService} from "../../Services/user.service";
import {Router} from "@angular/router";

import {CalendarOptions} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
@Component({
  selector: 'calendarComponent',
  styleUrls: ['calendar.component.css'],
  templateUrl: 'calendar.component.html'
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input() selectedUser: User = null;

  currentYear = new Date().getFullYear();
  previousYear = new Date().getFullYear() - 1;
  nextYear = new Date().getFullYear() + 1;

  // WIP: Faire marcher FullCalendar
  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      multiMonthPlugin
    ],
    headerToolbar: {
      center: 'dayGridMonth,multiMonthYear,multiMonthFourMonth',
      end: 'today prev,next'
    },
    views: {
      multiMonthFourMonth: {
        buttonText: '4 months',
        type: 'multiMonth',
        duration: { months: 4 }
      },
      multiMonthYear: {
        validRange: {
          start: `${this.previousYear}-01-01`,
          end: `${this.currentYear}-12-31`,
        },
      }
    },
    validRange: {
      start: `${this.previousYear}-01-01`,
      end: `${this.nextYear}-12-31`,
    },
    displayEventTime: false,
    weekends: false,
    eventDisplay: 'block',
    initialView: 'dayGridMonth',
    select: this.handleDayClick.bind(this),
    dateClick: this.handleDayClick.bind(this),
    eventClick: this.handleEventClick.bind(this)
  }


  months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: string[];

  //FullCalendar
  @ViewChild('fccontainer') fcContainer!: any;
  @ViewChild('fc') fc!: any;

  events: any[] = [];
  pendingEvents: CalendarEventHolder[] = [];
  header = {
    left: '',
    center: '',
    right: ''
  };
  options = {
    firstDay: 1
  };

  selectedMonth: string;
  selectBoxMonthOpen: boolean = false;
  selectedYear: string;
  selectBoxYearOpen: boolean = false;

  //User Modal
  userModalOpened: boolean = false;
  currentEvent: CalendarEventHolder = new CalendarEventHolder();

  //justificationModal
  justificationModalTitle: string;
  justificationModalOpened: boolean = false;

  //Notification
  showAlert: boolean = false;
  alertType: string;
  alertMessage: string;
  warningsMessage: string[] = [];

  // LocalTimeZone
  localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


  constructor(private userService: UserService, private toolsBoxService: ToolsBoxService, private router: Router) {
    const currentDate: Date = new Date();
    this.selectedMonth = this.months[currentDate.getMonth()];
    this.selectedYear = currentDate.getFullYear().toString();


    let yearMin2: string = (currentDate.getFullYear() - 2).toString();
    let yearMin1: string = (currentDate.getFullYear() - 1).toString();
    let yearPlus1: string = (currentDate.getFullYear() + 1).toString();
    let yearPlus2: string = (currentDate.getFullYear() + 2).toString();

    this.years = [yearMin2, yearMin1, this.selectedYear, yearPlus1, yearPlus2];
  }

  ngOnInit() {
    //Fullcalendar initialization
    if (!this.selectedUser) {
      //Getting list of events
      this.getEventList(null);
    }
  }

  ngOnChanges() {
    this.events = [];
    this.getEventList(this.selectedUser);
  }

  //initialization of the first tab
  getEventList = (selectedUser: User) => {
    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getUserEvent(selectedUser).subscribe(
      (listEventJSON: CalendarEventHolder[]) => {
        this.events = [];
        //For each event
        for (let currentEventJSON of listEventJSON) {
          //Convert from EventHolder to Event
          let e: CalendarEvent = new CalendarEvent();
          e.convertFromHolder(currentEventJSON);

          //Adding Event to the list
          this.events.push(e);
        }
         // After processing all events, check for overlaps
        const leaveSickTypesOverlapMessage = this.checkOverlapsDaysOffSickDaysPublicHolidays(this.events, 'OFF', ['SICK', 'KIDS'], 'Day off and sick day overlap on');

        // Update warnings
        if (leaveSickTypesOverlapMessage) {
          this.warningsMessage = [leaveSickTypesOverlapMessage];
          this.alertType = 'text-warning';
          this.showAlert = true;
        }

        this.toolsBoxService.tellActionIsInProgress(false);
      },
      (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    );
  }

  checkOverlapsDaysOffSickDaysPublicHolidays(events: CalendarEvent[], primaryConstraint: string, secondaryConstraints: string[], messagePrefix: string): string {
    const primaryEvents = events.filter(event => event.constraint === primaryConstraint);
    const secondaryEvents = events.filter(event => secondaryConstraints.includes(event.constraint));

    const overlappingDates: string[] = [];

    primaryEvents.forEach(primary => {
        const primaryStartDate = new Date(primary.start);
        const primaryEndDate = new Date(primary.end);

        secondaryEvents.forEach(secondary => {
            const secondaryStartDate = new Date(secondary.start);
            const secondaryEndDate = new Date(secondary.end);

            if (primaryStartDate <= secondaryEndDate && primaryEndDate >= secondaryStartDate) {
                let currentDate = new Date(Math.max(primaryStartDate.getTime(), secondaryStartDate.getTime()));
                const lastDate = new Date(Math.min(primaryEndDate.getTime(), secondaryEndDate.getTime()));

                while (currentDate <= lastDate) {
                  currentDate.setDate(currentDate.getDate() + 1);
                  const formattedDate = currentDate.toISOString().split('T')[0];
                  overlappingDates.push(formattedDate);
                }
            }
        });
    });

    const primaryDescription = this.getConstraintDescription(primaryConstraint);
    const secondaryDescription = secondaryConstraints.map(constraint => this.getConstraintDescription(constraint)).join(', ');

    const uniqueDates = [...new Set(overlappingDates)];
    return uniqueDates.length > 0
       ? `${messagePrefix} : ${uniqueDates.join(', ')}. Please correct the start/end date of your day off or contact for help support.` : null;
  }

  getConstraintDescription(constraint: string): string {
    const descriptions: { [key: string]: string } = {
        OFF: "Day off",
        SICK: "Sick leave",
        KIDS: "Kids sicnkness leave"
    };

    return descriptions[constraint] || "Unknown constraint";
  }


  //When the user clicks the + button or click an empty day  => NEW EVENT
  //this.currentEvent will be initialized with dummy value the user will have to change
  handleDayClick(selectInfo: any) {
    console.log("handleDayClick");
    //In case Calendar is displayed through Overview, there is no action allowed
    if (this.selectedUser != null) {
      return;
    }

    //If there is a date, the event comes from FullCalendar (it is a day)
    if (selectInfo) {
      // format yyyy-MM-dd
      this.currentEvent.eventStartDate = selectInfo.dateStr;
      this.currentEvent.eventEndDate = selectInfo.dateStr;
    } else {
      //If there is no date, we come due to an action of the button
      this.currentEvent.eventStartDate = new Date().toISOString().split('T')[0];
      this.currentEvent.eventEndDate = new Date().toISOString().split('T')[0];
    }
    this.currentEvent.eventId = 0;
    this.currentEvent.eventUser = new User(Number(this.toolsBoxService.getStoredUserId()));
    this.currentEvent.eventStartPeriod = 'AM';
    this.currentEvent.eventEndPeriod = 'PM';
    this.currentEvent.eventType = "OFF";
    this.currentEvent.eventDesc = '';
    this.currentEvent.eventUpdDesc = "";
    this.currentEvent.eventStatus = 'NEW';

    this.userModalOpened = true;
  }

  //When the user clicks an existing event => View or Update
  //this.currentEvent will be initialized with the selected event values
  handleEventClick(clickInfo: any) {
    let status: string;
    let e = clickInfo.event;
    if (e.classNames.includes("event-corrected")) {
      status = 'CORRECTED';
    } else if (e.classNames.includes("event-pending")) {
      status = 'PENDING';
    } else if (e.classNames.includes("event-deleted")) {
      status = 'DELETED';
    } else {
      status = 'VALIDATED';
    }

    let start = e.start?.toLocaleString('fr-FR', { timeZone: this.localTimeZone }).split(' ');
    // If the event is on one day, we take the start date of the event
    let end = e.end?.toLocaleString('fr-FR', { timeZone: this.localTimeZone }).split(' ');

    //Init of the currentEvent
    this.currentEvent.eventId = +e.id;
    this.currentEvent.eventDesc = e.title;

    // Get the event code from event
    this.currentEvent.eventType = e.constraint ? JSON.parse(JSON.stringify(e.constraint)) : null;

    this.currentEvent.eventStartDate = start[0]
      // On doit avoir des dates au format yyyy-mm-dd
      .split("/").reverse().join("-");
    if (start[1].includes("00:00:00")) {
      this.currentEvent.eventStartPeriod = 'AM';
    } else if (start[1].includes("12:00:00")) {
      this.currentEvent.eventStartPeriod = 'PM';
    } else {
      this.currentEvent.eventStartPeriod = 'AW';
    }

    this.currentEvent.eventEndDate = end[0]
      // On doit avoir des dates au format yyyy-mm-dd
      .split("/").reverse().join("-");
    this.currentEvent.eventEndPeriod = (end[1].includes("12:00:00") ? 'AM' : 'PM');

    this.currentEvent.eventUser = new User(Number(this.toolsBoxService.getStoredUserId()));
    this.currentEvent.eventUpdDesc = "";
    this.currentEvent.eventStatus = status;

    this.userModalOpened = true;
  }

  //Depending on the action, this.currentEvent will be updated
  manageEvent = (mode: string, eventId: number) => {
    //In case "mode" in included in the following array, we won't send anything
    //We will only open the justificationModal
    if (Array.of('VALIDATED', 'REFUSED', 'RESTORE', 'CANCEL', 'DELETE').includes(mode)) {
      //If the event is accepted or refused by the ADMIN, we only need to set the three following attributes
      //Same thing if the event is canceled by the user
      this.currentEvent.eventId = eventId;
      this.currentEvent.eventStatus = mode;
      this.initJustificationModal(mode);
      //Then open the confirmation box
      this.justificationModalOpened = true;

    } else if (mode == 'SEND') {
      //In case "eventStatus" in included in the following array, the update concerns an ITz
      if (Array.of('RESTORE', 'CANCEL', 'DELETE').includes(this.currentEvent.eventStatus)) {
        this.toolsBoxService.tellActionIsInProgress(true);
        return this.userService.sendUserEvent(this.currentEvent).subscribe({
          next : () => {
            this.show();
            this.toolsBoxService.tellActionIsInProgress(false);
          },
          error : (err) => {
            this.toolsBoxService.tellActionIsInProgress(false);
            this.router.navigate(["Error", {errMessage: err.message}]);
          }
        })
      }
      //The update concerns the Admin
      else {
        this.toolsBoxService.tellActionIsInProgress(true);
        //Sending event to be validated/rejected by the ADMIN
        return this.userService.manageRequest(this.currentEvent).subscribe(
          (data) => {
            this.toolsBoxService.tellActionIsInProgress(false);
            this.show();
          },
          (err) => {
            this.toolsBoxService.tellActionIsInProgress(false);
            this.router.navigate(["Error", {errCode: err.status, errMessage: err.json().message}]);
          }
        )
      }
    }
  }

  initJustificationModal = (mode: String) => {
    switch (mode) {
      case "VALIDATED":
        // Admin
        this.justificationModalTitle = "Accept request";
        this.currentEvent.eventUpdDesc = "Request validated by " + this.toolsBoxService.getStoredUserLogin();
        break;
      case "REFUSED":
        // Admin
        this.justificationModalTitle = "Reject request";
        this.currentEvent.eventUpdDesc = "Request rejected by " + this.toolsBoxService.getStoredUserLogin();
        break;
      case "RESTORE":
        // User
        this.justificationModalTitle = "Restore event";
        this.currentEvent.eventUpdDesc = "Correction / deletion canceled by " + this.toolsBoxService.getStoredUserLogin();
        break;
      case "CANCEL":
        // User
        this.justificationModalTitle = "Cancel request";
        this.currentEvent.eventUpdDesc = "Pending request canceled by " + this.toolsBoxService.getStoredUserLogin();
        break;
      case "DELETE":
        // User
        this.justificationModalTitle = "Delete request";
        this.currentEvent.eventUpdDesc = "Request deleted by " + this.toolsBoxService.getStoredUserLogin();
        break;
      default:
    }
  }


  //Manage the value returned by the user modal
  handleCloseEvent(myResult: any) {

    this.userModalOpened = false;
    this.justificationModalOpened = false;

    if (myResult == "OK") {
      this.show();
    } else if (myResult == "DELETE" || myResult == "CANCEL" || myResult == "RESTORE") {
      this.currentEvent.eventStatus = myResult;
      this.manageEvent(myResult, this.currentEvent.eventId);
    } else if (myResult == "SEND") {
      this.manageEvent(myResult, null);
    }
  }

  //Display the notification toast, refresh Events lists and close modals
  show() {
    this.alertType = 'success';
    this.alertMessage = 'Your update has been sent';
    this.showAlert = true;
    this.getEventList(null);
    //Todo EKA: pending?
    // if (this.isAdmin())
    //   this.getPendingEventList();
    this.userModalOpened = false;
    this.justificationModalOpened = false;
    this.toolsBoxService.tellActionIsInProgress(false);
  }

  openSelectBox = (box: String) => {
    if (box === 'month') {
      this.selectBoxMonthOpen = true;
    } else if (box === 'year') {
      this.selectBoxYearOpen = true;
    }
  }

  goToPreviousMonth = () => {
    let indexSelectMonth = this.months.indexOf(this.selectedMonth);

    // Change selected month and possibly year value
    if (indexSelectMonth == 0) {
      this.selectedMonth = this.months[11];
      this.selectedYear = (+this.selectedYear - 1).toString();
    } else {
      this.selectedMonth = this.months[indexSelectMonth - 1];
    }

    this.fcContainer.nativeElement.className = 'moveLeftToRight';
    setTimeout(() => {
      this.fcContainer.prev();
      this.fcContainer.nativeElement.className = '';
    }, 10);
  }

  goToNextMonth = () => {
    let indexSelectMonth = this.months.indexOf(this.selectedMonth);

    // Change selected month and possibly year value
    if (indexSelectMonth == 11) {
      this.selectedMonth = this.months[0];
      this.selectedYear = (+this.selectedYear + 1).toString();
    } else {
      this.selectedMonth = this.months[indexSelectMonth + 1];
    }

    this.fcContainer.nativeElement.className = 'moveRightToLeft';
    setTimeout(() => {
      this.fc.next();
      this.fcContainer.nativeElement.className = '';
    }, 10);
  }

  /* Called when month is changing */
  onMonthChange = (value: string) => {
    this.selectBoxMonthOpen = false;

    // Let's evaluate gap between current selected year and new selected year
    let indexSelectMonth = this.months.indexOf(this.selectedMonth);
    let indexValue = this.months.indexOf(value);
    let diff = indexSelectMonth - indexValue;

    // Change selected year value
    this.selectedMonth = value;

    if (diff < 0) {
      // New selected month is greater than old selected month
      for (let index = diff; index < 0; index++) {
        this.fcContainer.nativeElement.className = 'moveRightToLeft';
        setTimeout(() => {
          this.fc.next();
          this.fcContainer.nativeElement.className = '';
        }, 10);
      }
    } else if (diff > 0) {
      // New selected month is less than old selected month
      for (let index = 0; index < diff; index++) {
        this.fcContainer.nativeElement.className = 'moveLeftToRight';
        setTimeout(() => {
          this.fc.prev();
          this.fcContainer.nativeElement.className = '';
        }, 10);
      }
    }
  }

  /* Called when year is changing */
  onYearChange = (value: string) => {
    this.selectBoxYearOpen = false;

    // Let's evaluate gap between current selected year and new selected year
    let diff = +this.selectedYear - +value;

    // Change selected year value
    this.selectedYear = value;

    if (diff < 0) {
      // New selected year is greater than old selected year
      for (let index = diff; index < 0; index++) {
        this.fcContainer.nativeElement.className = 'moveRightToLeft';
        setTimeout(() => {
          this.fc.nextYear();
          this.fcContainer.nativeElement.className = '';
        }, 10);
      }
    } else if (diff > 0) {
      // New selected year is less than old selected year
      for (let index = 0; index < diff; index++) {
        this.fcContainer.nativeElement.className = 'moveLeftToRight';
        setTimeout(() => {
          this.fc.prevYear();
          this.fcContainer.nativeElement.className = '';
        }, 10);
      }
    }
  }
}
