import { User } from '../user.model';

/**
 * This objects represent an Event as it is received from the server.
 */
export class CalendarEventHolder {
  eventId: number;
  eventStartDate: string;
  eventStartPeriod: string;
  eventEndDate: string;
  eventEndPeriod: string;
  eventStatus: string;
  eventType:  string;
  eventDesc: string;
  eventUser: User;
  eventCreUsr: User;
  eventCreDt: string;
  eventUpdUsr: User;
  eventUpdDt: string
  eventUpdDesc: string;
  nbDaysOff: number;
  socialEventId: number;
  additionalDesc : string;
  eventLocation: string;
  eventLatitude: number;
  eventLongitude: number;
  participants: User[];
  invitationStatus: string;
  isSelected: boolean;
  percentOfWorkPerDay: number;

  constructor() {
    this.eventType = "";
    this.eventDesc = "";
    this.eventStartPeriod = "AM"
    this.eventEndPeriod = "PM"
    this.socialEventId = 0;
  }

    hasChanged= (compareTo: CalendarEventHolder) : boolean => {
        let $result: boolean =  (this.eventType != compareTo.eventType) ||
                        (this.eventStartDate != compareTo.eventStartDate) ||
                        (this.eventStartPeriod != compareTo.eventStartPeriod) ||
                        (this.eventEndDate != compareTo.eventEndDate) ||
                        (this.eventEndPeriod != compareTo.eventEndPeriod) ||
                        (this.eventDesc != compareTo.eventDesc) ||
                        (this.additionalDesc != compareTo.additionalDesc) ||
                        (this.eventLocation != compareTo.eventLocation);

        return  $result;
    }
}
