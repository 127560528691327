import { Pipe, PipeTransform} from '@angular/core';
import { User } from '../Models/user.model';

@Pipe({
    name: 'filtreUser'
})

export class UserPipe implements PipeTransform {

    transform(liste : User[], critereLastName: string, critereFirstName: string):  User[] {
        if (critereLastName && !critereFirstName){
            return liste.filter((item) =>  (item.userName.toUpperCase().startsWith(critereLastName.toUpperCase())));
        } else if (critereFirstName && !critereLastName){
            return liste.filter((item) =>  (item.userFirstName.toUpperCase().startsWith(critereFirstName.toUpperCase())));
        } else if (critereFirstName && critereLastName) {
            return liste.filter((item) =>  (item.userFirstName.toUpperCase().startsWith(critereFirstName.toUpperCase()) &&
                item.userName.toUpperCase().startsWith(critereLastName.toUpperCase())
            ));
        } else {
            return liste;
        }

    }
}
