import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Client} from '../Models/client.model';
import {User} from '../Models/user.model';
import {CallApi, HTTP_COMMAND} from "./callApi";


@Injectable()
export class ClientService {

constructor(private callApi: CallApi) {

  }

  //Used for Directory
  getClientList = (): Observable<Client[]> => {
    return this.callApi.call(HTTP_COMMAND.GET, '/admin/clients/');
  }

  //getUsersAndMissions
  getAllUsersByMission = (mission:Client):Observable<User[]> => {
    return this.callApi.call(HTTP_COMMAND.GET, '/admin/consultants/' + mission);
  }

}
