import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NotificationService} from "../Services/notification.service";

@Component({
  selector: 'notification',
  templateUrl: 'notification.component.html'
})
export class NotificationComponent implements OnChanges {

  @Input('severity') severity: string;
  @Input('timeout') timeout: number;
  @Input('message') message: string;
  @Output('hideNotification') onClose: EventEmitter<any> = new EventEmitter();

  className: String;
  displayNotif: boolean = false;

  constructor(private readonly notificationService: NotificationService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.severity === 'warning') {
      this.className = 'notif notif-warning';
    } else if (this.severity === 'info') {
      this.className = 'notif notif-info';
    } else {
      this.className = 'notif notif-error';
    }
    this.displayNotif = true;
    if (this.timeout) {
      this.displayNotif = true;
      setTimeout(() => {
        this.displayNotif = false;
        this.onClose.next(true);
        this.deleteContent();
      }, this.timeout);
    }

  }

  private deleteContent() {
    this.notificationService.setMessage(null);
    this.notificationService.setType(null)
  }
}
