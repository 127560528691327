import {CalendarEventHolder} from "./Holders/calendareventholder.model";
import {User} from "./user.model";

export class PlanningItem {
  event: CalendarEventHolder;
  agreeList: User[];
  refuseList: User[];
  pendingList: User[];

  constructor() {
  }
}
