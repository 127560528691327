import {Component, OnInit, ElementRef, NgZone, Input} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../Services/user.service';
import {ClientService} from '../../Services/client.service';
import {ToolsBoxService} from '../../Services/tools.service';
import {CalendarEventHolder} from '../../Models/Holders/calendareventholder.model';
import {User} from '../../Models/user.model';

//Import PRIMENG
import {SelectItem} from "primeng/api";
import {UntypedFormControl} from "@angular/forms";
import {SocialEventRequest} from "../../Models/Holders/SocialEventRequest.model";
import { SearchService } from '../../Services/search.service';



@Component({
  selector: 'eventManagement',
  styleUrls: ['./eventmanagement.component.css'],
  templateUrl: 'eventmanagement.component.html',

})

export class EventManagement implements OnInit {


  eventTemplate: CalendarEventHolder = new CalendarEventHolder();

  users: User[];
  userSelected: User[] = [];
  userFilter: string;

  periods: SelectItem[] = [{label: 'AM', value: 'AM'}, {label: 'PM', value: 'PM'}, {label: 'AW', value: 'AW'}];
  boolValues: SelectItem[] = [{label: 'YES', value: true}, {label: 'NO', value: false}];

  alertType: String;
  alertMessage: String;

  localInfo: any;
  allDay: boolean = true;


  //For googleMap
  searchControl: UntypedFormControl;
  lat: number = 49.6017639;
  lng: number = 6.0696469;
  zoom: number = 14 ;

  searchElementRef: ElementRef;
  setAddress: boolean = false;
  sendSlackNotification: boolean = false;
  slackNotification: string = 'Hello, a new event has been created. Please check your Smoothiz Dashboard!';
  sendMailNotification: boolean = true;



  constructor(private userService: UserService, private searchService: SearchService, private toolsBoxService: ToolsBoxService,
              private router: Router, private ngZone: NgZone) {

  }

  ngOnInit() {
    this.toolsBoxService.changeHeaderName("Event Management");
    this.toolsBoxService.tellChildContainsTabs(false);

    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getUserList().subscribe(
      (list: User[]) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.users = list;
      },
      (err: any) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    );
    this.localInfo = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear'
    };
    this.eventTemplate = new CalendarEventHolder();
    this.eventTemplate.eventType = 'OFF';
    this.eventTemplate.eventDesc = this.toolsBoxService.eventLabels['OFF'];

    //create search FormControl
    this.searchControl = new UntypedFormControl();
  }

  //In case the "All Day" checkbox is switched on, we set the period from AM to PM
  switchAllDayTag() {
    //We need a delay between the click and the following action since the boolean this.allDay was not updated immediately
    setTimeout(() => {
      if (this.allDay) {
        this.eventTemplate.eventStartPeriod = 'AM';
        this.eventTemplate.eventEndPeriod = 'PM';
      }
    }, 500);
  }


  getHeaderClass = () : string => {
    return "background-color-" + this.eventTemplate.eventType.toLowerCase();
  }

  /**
   * Check if an element is in list and return its index
   */
  getElementIndex = (list: User[], param: User): number => {
    let returnedIndex = -1;
    list.map((element, index) => {

      let user = Object.assign(new User(0), element);

      if (user.equals(param)) {
        returnedIndex = index;
      }

    });
    return returnedIndex;

  }

  /**
   * Add users in selectedList
   * @param i
   */
  addItzInList(user: User) {

    if (this.getElementIndex(this.userSelected, user) < 0) {
      this.userSelected.push(user);
      let indexInEmployee = this.getElementIndex(this.users, user);
      this.users.splice(indexInEmployee, 1);
    }

  }

  /**
   * remove User one by one
   * @param deleteItz
   */
  delItzInList(deleteItz: User) {
    this.users.push(deleteItz);
    let index = this.userSelected.indexOf(deleteItz);
    this.userSelected.splice(index, 1);
  }

  /**
   * Add all user in selectedList
   *
   */
  addAllItzInList() {
    this.users.map((user: User) => {
      this.userSelected.push(user);
    })
    this.users.splice(0);
  }

  /**
   * Remove all users in selectedList and restore initial user list
   */
  delAllItzInList() {
    this.userSelected.map((user: User) => {
      this.users.push(user);
    })
    this.userSelected.splice(0);
  }



  eventTypeChange(state: string) {
    this.eventTemplate.eventDesc = this.toolsBoxService.eventLabels[state];
    this.sendSlackNotification = Array.of('TRAINZ', 'CONFZ', 'CASUALZ', 'TECHZ').includes(state);
    this.slackNotification = 'Hello, a new ' + state + ' event has been created. Please check your Smoothiz Dashboard!'
  }


  isEventValid = (): boolean => {
    this.alertMessage = null;
    let startDate = new Date(this.eventTemplate.eventStartDate+"");
    let endDate = new Date(this.eventTemplate.eventEndDate+"");

    if ((this.eventTemplate.eventType == null) || (this.eventTemplate.eventType == "")) {
      //No Type
      this.alertType = 'error';
      this.alertMessage = 'No event type selected';
    } else if ((this.eventTemplate.eventStartDate == null) || (this.eventTemplate.eventEndDate == null)) {
      //No Date
      this.alertType = 'error';
      this.alertMessage = 'Both dates are mandatory';
    } else if (this.userSelected.length == 0) {
      //No ITz
      this.alertType = 'error';
      this.alertMessage = 'There is no Itz selected';
    } else if (this.eventTemplate.eventStartPeriod == 'AW' || this.eventTemplate.eventEndPeriod == 'AW') {

      if (this.eventTemplate.eventStartPeriod != this.eventTemplate.eventEndPeriod) {
        //Invalid Period
        this.alertType = 'error';
        this.alertMessage = 'The period is not valid (AW / ??)';
      } else if (startDate.getTime() != endDate.getTime()) {
        this.alertType = 'error';
        this.alertMessage = 'An afterwork event should occur the same day';
      }
    } else if ((startDate.getTime() == endDate.getTime()) &&
      (this.eventTemplate.eventStartPeriod == 'PM') && (this.eventTemplate.eventEndPeriod == 'AM')) {
      //Invalid Period
      this.alertType = 'error';
      this.alertMessage = 'The period is not valid';
    } else if (startDate.getTime() > endDate.getTime()) {
      //Invalid Dates
      this.alertType = 'error';
      this.alertMessage = 'The end date can not be set before the start date';
    } else if (this.eventTemplate.eventDesc == "" || this.eventTemplate.eventDesc == "Please set me") {
      //Invalid Desc
      this.alertType = 'error';
      this.alertMessage = 'Event description is mandatory';
    }
    return (this.alertMessage == null);
  }

  search = (): void => {
    this.users = this.searchService.search(this.users, this.userFilter);
  }

  resetFilter = (): void => {
    this.userFilter = "";

    this.search();
  }


  sendEvent() {
    if (this.isEventValid()) {
      this.eventTemplate.eventId = 0;
      this.eventTemplate.eventLatitude = this.lat;
      this.eventTemplate.eventLongitude = this.lng;

      let request: SocialEventRequest = new SocialEventRequest();
      request.draftEvent = this.eventTemplate;
      request.attendees = this.userSelected;
      request.mailNotif = this.sendMailNotification;
      request.slackNotif = this.sendSlackNotification;
      request.slackMessage = this.slackNotification;

      this.toolsBoxService.tellActionIsInProgress(true);
      let $returnSubs = this.userService.createEvents(request).subscribe(
        (_: Boolean) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.alertType = 'info';
          this.alertMessage = 'Event has been created';
          this.delAllItzInList();
        },
        (err: any) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(["Error", {errMessage: err.error}]);
        }
      )
    }
  }
}



