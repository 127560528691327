
import {Component, ElementRef, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {LowerCasePipe} from '@angular/common';
import {Router} from '@angular/router';

import {UserService} from '../../Services/user.service';
import {ToolsBoxService} from '../../Services/tools.service';
import {FirstLetterPipe} from '../../Pipes/firstletter.pipe';
import {CalendarEventHolder} from '../../Models/Holders/calendareventholder.model';

@Component({
  selector: 'timeManagement',
  styleUrls: ['timemanagement.component.css'],
  templateUrl: 'timemanagement.component.html',
  //encapsulation: ViewEncapsulation.None allow to disable encapsulation CSS
})
export class TimeManagement implements AfterViewInit {
  //justificationModal
  justificationModalTitle: String;
  justificationModalOpened: boolean = false;
  currentEvent: CalendarEventHolder = new CalendarEventHolder();
  pendingEvents: CalendarEventHolder[] = [];

  //Notification
  alertType: String;
  alertMessage: String;

  //Test
  displayedTab: number;

  constructor(private userService: UserService, private toolsBoxService: ToolsBoxService, private router: Router, private _elRef: ElementRef) {
  }

  ngAfterViewInit() {
    this.toolsBoxService.changeHeaderName("Time Management");
    this.toolsBoxService.tellChildContainsTabs(this.isAdmin());
  }

  //When the first tab is displayed, we retrieve the list of user's Events
  //When the second tab is displayed, we retrieve the list of pending Events
  onTabChange(e: any) {
    this.pendingEvents = [];
    if (e.index == 2) {
      this.displayedTab = 2;
      // console.log('TimeManagement - onTabChange => getPendingEventList');
      this.getPendingEventList();
    } else if (e.index == 1) {
      this.displayedTab = 1;
      // console.log('TimeManagement - onTabChange => #1');
      //TODO EKA: Deal a vars "isTabDisplay (true false)
    } else if (e.index == 0) {
      this.displayedTab = 0;
      // console.log('TimeManagement - onTabChange => getEventList');
      // this.getEventList(null);
    }

    if (document.getElementById('body-content') != null) {
      document.getElementById('body-content').scrollTop = 0;
    }
  }


  //initialization of the second tab (ADMIN profile)
  getPendingEventList = () => {
    this.pendingEvents = [];
    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getPendingRequest().subscribe(
      (listEventJSON: CalendarEventHolder[]) => {
        this.pendingEvents = listEventJSON;
        this.toolsBoxService.tellActionIsInProgress(false);
      },
      (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    );
  }


  getStyleForAvatar = (userName: string): String => {
    let firstLetterPipe = new FirstLetterPipe();
    return "user-first-initial-" + firstLetterPipe.transform(userName);
  }

  getStyleForRequest = (eventType: string): String => {
    let lowerCasePipe = new LowerCasePipe();
    return "list-group-item-event event-" + lowerCasePipe.transform(eventType);
  }
  getLabelForRequest = (eventStatus: String): String => {
    if (eventStatus == ('PENDING')) {
      return 'requested';
    } else if (eventStatus == ('CORRECTED')) {
      return 'corrected';
    } else if (eventStatus == ('DEL_REQUESTED')) {
      return 'deleted';
    }
  }
  //Tabs are only displayed if the current user has ADMIN profile
  isAdmin = (): boolean => {
    return this.toolsBoxService.isAdmin();
  }

  //Tabs are only displayed if the current user has MANAGER profile
  isManager = (): boolean => {
    return this.toolsBoxService.isManager();
  }

  isPastEvent = (startDate: string) => {
    return (Date.parse(startDate) < Date.now());
  }

  manageEvent = (mode: string, eventId: number) => {
    this.currentEvent.eventId = eventId;
    this.currentEvent.eventStatus = mode;
    switch (mode) {
      case "VALIDATED":
        // Admin
        this.justificationModalTitle = "Accept request";
        this.currentEvent.eventUpdDesc = "Request validated by " + this.toolsBoxService.getStoredUserLogin();
        break;
      case "REFUSED":
        // Admin
        this.justificationModalTitle = "Reject request";
        this.currentEvent.eventUpdDesc = "Request rejected by " + this.toolsBoxService.getStoredUserLogin();
        break;
      default: ;
    }
    //Then open the confirmation box
    this.justificationModalOpened = true;
  }

  //Manage the value returned by the user modal
  handleCloseEvent(mode: any) {

    this.justificationModalOpened = false;

    if (mode == 'SEND') {
      this.toolsBoxService.tellActionIsInProgress(true);
      //Sending event to be validated/rejected by the ADMIN
      var $returnSubs = this.userService.manageRequest(this.currentEvent).subscribe(
        (data) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.show();
        },
        (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(["Error", {errMessage: err.message }]);
        }
      )
    }
  }

  //Display the notification toast, refresh Events lists and close modals
  show() {
    this.alertType = 'success';
    this.alertMessage = 'Your update has been sent';
    if (this.isAdmin())
      this.getPendingEventList();
    this.justificationModalOpened = false;
    this.toolsBoxService.tellActionIsInProgress(false);
  }

  getEventLabel = (code: string) :  string => {
    return this.toolsBoxService.eventLabels[code];
  }
}
