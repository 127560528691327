import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

export interface CreationItzModel {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  userLogin: FormControl<string>;
  userMailAddress: FormControl<string>;
  userClientName: FormControl<string>;
  userRecordID?: FormControl<string | null>;
  isAdmin: FormControl<boolean>;
  isBlocked: FormControl<boolean>;
}

export function allControlsValidValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    if (!(formGroup instanceof FormGroup)) {
      throw new Error('allControlsValidValidator doit être utilisé sur un FormGroup.');
    }

    // Liste des contrôles à exclure de la validation
    const excludedControls = ['userRecordID']; // Ajoutez ici les champs à ignorer

    // Parcourir les contrôles du FormGroup
    const invalidControls = Object.keys(formGroup.controls).filter((key) => {
      // Ne pas valider les contrôles exclus
      if (excludedControls.includes(key)) {
        return false;
      }

      const control = formGroup.get(key);

      // Vérifie que le contrôle est bien un FormControl, qu'il utilise le validateur `required` et qu'il est valide
      if (control instanceof FormControl) {
        return !control.hasValidator(Validators.required) || control.invalid;
      }

      return false;
    });

    // Retourne une erreur s'il y a des contrôles invalides
    if (invalidControls.length > 0) {
      return { requiredNotPresent: invalidControls }; // Retourne les noms des champs invalides
    }

    // Sinon, tout est valide
    return null;
  };
}


export function loginFormatValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Expression régulière pour le format de login
    const LOGIN_REGEXP = /^[\p{Ll}0-9!#$%&'*+\/=?^_`{|}~.-]+$/u;

    if (!control.value || control.value === '') {
      // Pas d'erreur si le champ est vide (le login n'est pas obligatoire ici)
      return null;
    }

    const login = control.value.toString().toLowerCase();

    // Vérifie si le login ne respecte pas l'expression régulière
    if (!LOGIN_REGEXP.test(login)) {
      return { invalidLoginFormat: true }; // Retourne une erreur si invalide
    }

    return null; // Aucune erreur
  };
}
