import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../Services/user.service';
import {ToolsBoxService} from '../../Services/tools.service';
import {User} from '../../Models/user.model';
import {BillingHolder} from "../../Models/Holders/billingholder.model";
import { BalanceHolder } from '../../Models/Holders/balanceholder.model';

@Component({
  selector: 'tscheck',
  styleUrls: ['tscheck.component.css'],
  templateUrl: 'tscheck.component.html'
})
export class TSCheckComponent implements OnInit {

  constructor(private userService: UserService, private toolsBoxService: ToolsBoxService, private router: Router) {
  }
  @Input() selectedUser: User = null;
  @ViewChild('defaultSelected') liClick: ElementRef;

  users: User[];
  displayedLogin: String;
  selectedYear: number = new Date().getFullYear();
  displayCurrentYear: boolean = true;
  billingStats: { month: '', totalBillable: 0, totalOffDuty: 0, billed: 0 } [];
  isAdmin: boolean = false;
  dayDisplayAllowed: boolean = false;
  displayDays: boolean = false;
  selectedBilled: BillingHolder;
  isDefaultSelectedTab: String = 'selectedUserTab';

  takenCount: number;
  offCount: number;

  ngOnInit() {
    this.toolsBoxService.changeHeaderName("Timesheet Check");
    this.toolsBoxService.tellChildContainsTabs(false);
    //Set the display depending on user's role
    this.isAdmin = this.toolsBoxService.getStoredUserProfile() == 'ADMIN';
    this.displayedLogin = this.toolsBoxService.getStoredUserLogin();
    if (this.isAdmin) {
      this.setSelectedTab("allUsersTab");
      this.toolsBoxService.tellActionIsInProgress(true);
      this.userService.getUserList().subscribe({
        next: (list: User[]) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.users = list;
          this.selectedUser = this.users.find(u => u.userLogin === this.displayedLogin);
          this.loadUserData();
          this.users.forEach(user => this.getUserBalance(user));
        },
        error: (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(["Error", {errMessage: err.error}]);
        }
      });
    } else {
      this.setSelectedTab('selectedUserTab');
      this.loadUserData();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.liClick.nativeElement.click();
    });
  }

  loadUserData() {
    this.getBilledHours();
    this.getUserBalance();
  }

  setSelectedTab(tab: String) {
    this.isDefaultSelectedTab = tab;
  }

  switchModeUnit = () => {
    this.displayDays = ! this.displayDays;
  }

  switchSelectedYear = () => {
    this.displayCurrentYear = ! this.displayCurrentYear;
    this.takenCount = this.offCount = 0; // reset when changing year
    this.loadUserData();
  }

  //initialization of the first tab
  getBilledHours = () => {
    let selectedYear = this.displayCurrentYear ? this.selectedYear : this.selectedYear-1;
    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getUserBilledHours(this.selectedUser, selectedYear).subscribe({
      next: (billingHolder: BillingHolder) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.billingStats = billingHolder.billingStatsList;
        this.selectedBilled = billingHolder;
        this.dayDisplayAllowed = this.selectedBilled?.displayDays || false;
        this.displayDays = this.selectedBilled?.displayDays || false;
      },
      error: (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    });

  }

  getUserBalance = (user?: User) => {
    this.toolsBoxService.tellActionIsInProgress(true);

    const currentUser = user != null ? user : this.selectedUser;

    let currentYear = this.displayCurrentYear ? this.selectedYear : this.selectedYear-1;
    this.userService.getUserBalance(currentUser.id, currentYear).subscribe({
      next: (holder: BalanceHolder) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        if (user) {
          user.takenDaysOffCount = holder.takenCount;
          user.totalDaysOffCount = holder.offCount + holder.previousYearFinalBalance;
        } else {
          this.takenCount = holder.takenCount;
          this.offCount = holder.offCount + holder.previousYearFinalBalance;// total of current year + previous year
        }
      },
      error: (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    });
  }

  getProgressbarLabel = (paramOne: number, paramTwo: number) => {
    if (this.displayDays) {
      return (paramOne/8) + " days / " +(paramTwo/8) + " days";
    } else {
      return paramOne + " hours / " + paramTwo + " hours";

    }
  }

  getProgressbarLabelDaysOff = (paramOne: number, paramTwo: number) => {
    paramOne = Number(paramOne.toFixed(2));
    paramTwo =  Number(paramTwo.toFixed(2));
    if (this.displayDays) {
      return paramOne + " days / " + paramTwo + " days";
    } else {
      return (paramOne*8) + " hours / " + (paramTwo*8) + " hours";

    }
  }

  calculateProgress(paramOne: number, paramTwo: number): number {
    let result = (paramOne / paramTwo) * 100;
    return result > 100 ? 100 : result;
  }

  getClassForSeason(stat: any): string {
    const month = new Date(stat.month).getMonth() + 1;

    if (month <= 2 || month === 12) {
      return 'winter';
    } else if (month >= 3 && month <= 5) {
      return 'spring';
    } else if (month >= 6 && month <= 8) {
      return 'summer';
    } else {
      return 'fall';
    }
  }

  getClassDaysOff(paramOne: number, paramTwo: number): string {
    const progress = this.calculateProgress(paramOne, paramTwo);
    if (progress >= 75) {
      return 'danger';
    } else if (progress >= 50) {
      return 'warning';
    } else if (progress >= 25) {
      return 'info';
    } else {
      return 'success';
    }
  }

  downloadTimesheet(month: string) {
      let selectedYear = this.displayCurrentYear ? this.selectedYear : this.selectedYear-1;
      this.userService.getTimesheetExport(selectedYear, month).subscribe(data => {});
  }
}
