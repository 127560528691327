import { Component, OnInit, OnChanges, Input, ViewEncapsulation, Pipe } from '@angular/core';
import {Router} from '@angular/router';
import {ToolsBoxService} from '../../Services/tools.service';
import { UserService } from '../../Services/user.service';
import {BalanceHolder} from '../../Models/Holders/balanceholder.model';
import {CalendarEventHolder} from '../../Models/Holders/calendareventholder.model';
import {User} from '../../Models/user.model';
import { Workday } from '../../Models/work-day.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnChanges {

  @Input() selectedUser: User = null;
  @Input() displayedAsTab: boolean = false;

  years: number[] = [new Date().getFullYear() - 1, new Date().getFullYear()];
  selectBoxOpen: boolean = false;

  authentificatedUser: User = new User;
  startDate: string;
  endDate: string;
  daysOff: string;
  workTimePercent: string = '100%';

  balanceYear: number;
  eholDetail: CalendarEventHolder[];
  offDetail: CalendarEventHolder[];
  otherDetail: CalendarEventHolder[];
  sickDetail: CalendarEventHolder[];
  kidsDetail: CalendarEventHolder[];
  trainDetail: CalendarEventHolder[];
  hbwDetail: CalendarEventHolder[];
  pholList: CalendarEventHolder[];
  pendingRequests: CalendarEventHolder[];
  currentYearBalance: number;
  previousYearFinalBalance: number;
  offCount: number;
  eholCount: number;
  takenCount: number;
  sickDaysCount: number;
  kidsSickDaysCount: number;
  hbwCount: number;

  //Information displayed
  nextEvents: CalendarEventHolder[] = [];
  upcomingEvents: CalendarEventHolder[] = [];
  nextDayOff: CalendarEventHolder;

  selectedYear: number = new Date().getFullYear();

  //Event label
  eventTypeLabels: any;

  //DataList
  modalLabel: String;
  modalType: String;
  displayDetail: boolean = false;
  currentDetail: CalendarEventHolder[];

  constructor(private datePipe: DatePipe, private userService: UserService, private toolsBoxService: ToolsBoxService, private router: Router) {
  }

  //
  ngOnChanges() {
    //If there is no selected user, we display Main Dashboard
    //Balance initialisation is required.
    if (this.selectedUser) {
      // console.log('Dashboard - onChange => getUserStats', this.selectedUser);
      this.getUserStats();
    }
    //If this.selectedUser is set, we display the Dashboard from Overview Component
    //Balance init is not required yet
  }

  ngOnInit() {

    this.getUserProfileInformations();

    if (!this.selectedUser) {
      //If this function is called with a selectedUser, we do not change the HeaderName since we are in the Admin Overview
      this.toolsBoxService.changeHeaderName("Dashboard");
    }
    this.toolsBoxService.tellChildContainsTabs(false);
    this.eventTypeLabels = this.toolsBoxService.eventLabels;
    //If there is no selected user, we display Main Dashboard
    //Balance initialisation is required.
    if (this.selectedUser === null) {
      this.getUserStats();
    }
    //If this.selectedUser is set, we display the Dashboard from Overview Component
    //Balance init is not required yet
  }

  getUserProfileInformations = () => {
    this.authentificatedUser.id = Number(this.toolsBoxService.getStoredUserId());

    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getUserInformation(this.authentificatedUser).subscribe({
      next: (user: User) => {
        this.authentificatedUser = user;
        this.startDate = this.datePipe.transform(this.authentificatedUser.effectiveStartDate, 'dd/MM/yyyy');
        this.endDate = this.datePipe.transform(this.authentificatedUser.effectiveEndDate, 'dd/MM/yyyy');
        this.toolsBoxService.tellActionIsInProgress(false);
      },
      error: (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    });

    this.getOffDaysByUser(this.authentificatedUser);
  }

  getOffDaysByUser(currentUser: User) {
    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getUserPartTime(currentUser).subscribe({
      next: (events: CalendarEventHolder[]) => {
        let eventsOffDaysOnly = events.filter(event => event.eventType === 'PARENTAL_LEAVE' ||  event.eventType === 'PART_TIME');
        let listWithPercentAndDays = this.getUniqueWeekdays(eventsOffDaysOnly);
        //Check if specific case (0 and 1 days every other week)
        let isOnlyOnePercentList = this.allPercentagesAreOne(listWithPercentAndDays);
        if (isOnlyOnePercentList) {
          this.daysOff = listWithPercentAndDays.filter(day => day.percentage == 1).map(workday => `${workday.day}`).join(', ');
          this.workTimePercent = '50%';
        } else {
          // Get list of days off to display on HTML
          this.daysOff = listWithPercentAndDays.filter(day => day.percentage == 0 || day.percentage == 0.5).map(workday => `${workday.day}`).join(', ');
          // Get percentage of work time
          this.workTimePercent = this.getWorkTimePercent(listWithPercentAndDays);
        }

        this.toolsBoxService.tellActionIsInProgress(false);
      },
      error: (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", {errMessage: err.error}]);
      }
    });
  }

  getUniqueWeekdays(events: CalendarEventHolder[]): Workday[] {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const uniqueWorkdays = new Map<string, number>();

      events.forEach(event => {
          const currentDate = new Date(event.eventStartDate).getDay();
          const dayOfWeek = daysOfWeek[currentDate];

        if (currentDate !== 0 && currentDate !== 6) {
          uniqueWorkdays.set(dayOfWeek, event.percentOfWorkPerDay);
        }
      });

      return Array.from(uniqueWorkdays.entries()).map(([day, percentage]) => ({ day, percentage }));
  }

  allPercentagesAreOne(workdays: Workday[]): boolean {
    return workdays.every(workday => workday.percentage === 1);
  }

  getWorkTimePercent(days: Workday[]): string {
    let totalNumberWork = 40;

    let workedHour = days.filter(day => day.percentage > 0).reduce((totalHours, day) => totalHours + (day.percentage * 8), 0);

    return Math.round(((workedHour * 100) / totalNumberWork) * 10) / 10 + '%';
  }

  getUserStats = () => {
    this.toolsBoxService.tellActionIsInProgress(true);

    let userIdParam: number = this.selectedUser?this.selectedUser.id:null;
    this.userService.getUserBalance(userIdParam, this.selectedYear).subscribe(
      (holder: BalanceHolder) => {
        this.balanceYear = holder.balanceYear;
        this.currentYearBalance = holder.currentYearBalance;
        this.previousYearFinalBalance = holder.previousYearFinalBalance;
        this.offCount = holder.offCount;
        this.eholCount = holder.eholCount;
        this.takenCount = holder.takenCount;
        this.hbwCount = holder.hbwCount;
        this.sickDaysCount = holder.sickDaysCount;
        this.kidsSickDaysCount = holder.kidsSickDaysCount;

        this.eholDetail = holder.eholDetail;
        this.trainDetail = holder.trainDetail;
        this.otherDetail = holder.otherDetail;
        this.sickDetail = holder.sickDetail;
        this.kidsDetail = holder.kidsDetail;
        this.offDetail = holder.offDetail;
        this.hbwDetail = holder.hbwDetail;
        this.pholList = holder.pholList;
        this.pendingRequests = holder.pendingRequests;
        this.nextDayOff = holder.nextDayOff;
        this.upcomingEvents = [];
        this.nextEvents = [];
        holder.upcomingEvents.forEach(element => {
          if (element.invitationStatus === "ACCEPTED" || element.invitationStatus === "MANDATORY"){
            this.nextEvents.push(element);
          } else {
            this.upcomingEvents.push(element);
          }
        })
        this.toolsBoxService.tellActionIsInProgress(false);
      },
      (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        if (err) {
          this.router.navigate(["Error", {errMessage: err.error}]);
        }
      });
  }


  showDetail = (code: any) => {
    this.currentDetail = null;
    this.modalLabel = '';
    this.modalType = '';
    switch (code) {
      case 'EXTRA':
        this.currentDetail = this.eholDetail;
        this.modalLabel = 'Extra-legal holidays ' + this.selectedYear;
        this.modalType = 'extra';
        break;
      case 'HOLIDAYS':
        this.currentDetail = this.offDetail;
        this.modalLabel = 'Planned / Taken Holidays ' + this.selectedYear;
        this.modalType = 'off';
        break;
      case 'SICK':
        this.currentDetail = this.sickDetail;
        this.modalLabel = 'Days for sickness ' + this.selectedYear;
        this.modalType = 'sick';
        break;
      case 'KIDS':
        this.currentDetail = this.kidsDetail;
        this.modalLabel = 'Days for kids sickness ' + this.selectedYear;
        this.modalType = 'kids';
        break;
      case 'HBW':
        this.currentDetail = this.hbwDetail;
        this.modalLabel = 'Home based working ' + this.selectedYear;
        this.modalType = 'hbw';
        break;
      default:
        break;
    }

    this.displayDetail = true;
  }

  /* Called when year is changing */
  onYearChange = (value: number) => {
    this.selectBoxOpen = false;
    if (value != this.selectedYear) {
      // Change selected year value
      this.selectedYear = Number(value);

      this.nextEvents = [];
      this.upcomingEvents = [];

      this.getUserStats();
    }
  }

  /* answer invitations */
  handleParticipationResponse = (currentEvent: CalendarEventHolder, willParticipate: boolean) => {
    //In case Dashboard is displayed through Overview, there is no action allowed
    if (this.selectedUser != null) {
      return;
    }
    currentEvent.invitationStatus = (willParticipate?"ACCEPTED":"REFUSED");
    this.toolsBoxService.tellActionIsInProgress(true);
    let result = this.userService.handleEventInvitation(currentEvent).subscribe(
      (data) => {
        this.upcomingEvents = [];
        this.nextEvents = [];
        data.map(planningItem => planningItem.event)
          .map(element => {
          if (element.invitationStatus === "ACCEPTED" || element.invitationStatus === "MANDATORY"){
            this.nextEvents.push(element);
          } else {
            this.upcomingEvents.push(element);
          }
        })
        this.toolsBoxService.tellActionIsInProgress(false);
      },
      (err) => {
        if (err) {
          this.router.navigate(["Error", {errMessage: err.error}]);
        }

        this.toolsBoxService.tellActionIsInProgress(false);
      })
    ;
  }

  getEventTypeDescription = (eventStatus: string) => {
    let labelDisplayed = "";
    switch (eventStatus)  {
      case "DEL_REQUESTED":
        labelDisplayed = "IN CANCELLING";
        break;
      default:
        labelDisplayed = eventStatus;
        break;
    }
    return labelDisplayed;
  }

  getSituationType(user: User) {
      if (user.parentalLeave) {
        return 'My parental leave planning';
      } else if (user.partTime) {
        return 'My part time planning';
      }
      return 'No informations';
  }

  redirectToEventInfo = (eventId: number) => {
    this.router.navigate(["Planning/"+eventId]);
  }

  returnString(balanceYear: number): string {
    return balanceYear.toString();
  }

  returnToStringMap(years: number[]): string[] {
    return years.map(String);
  }

  displayYears () {
    return "Events " + this.years[0] + "/" + this.years[1];
  }
}
