import {User} from './user.model';

export class Balance {

  balanceId: number;
  balanceUsr: User;
  eventType: string;
  balanceYear: string;
  balanceDesc: string;
  balance: number;
  status: string;
  editing: boolean;

  constructor() {
    this.balanceId = 0;
    this.balanceUsr = new User();
    this.eventType = "";
    this.balanceYear = new Date().getFullYear().toString();
    this.balanceDesc = "";
    this.balance = 0;
    this.status = "";

  }

}

export class BalanceRestModel {
  id: number;
  event: string;
  year: number;
  balanceDesc: string;
  balance: number;

  constructor() {
    this.id = 0;
    this.event = "";
    this.year = new Date().getFullYear();
    this.balanceDesc = "";
    this.balance = 0;
  }

  toBalanceModel(): Balance {
    const res = new Balance();
    return {
      ...res,
      balanceId: this.id,
      eventType: this.event,
      balanceYear: this.year.toString(),
      balanceDesc: this.balanceDesc,
      balance: this.balance,
    };
  }

}







