import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trigrammePipe'
})

export class TrigrammePipe implements PipeTransform {

    transform(userLogin: String): String {
      let $result: String;
      if (userLogin.length == 0) {
        return "";
      }
      let info: String[] = userLogin.split('.');

      $result = info.length > 1 ? info[0]?.slice(0,1) + info[1]?.slice(0,2) : info[0];
      return $result.toUpperCase();
    }
}
