import { CalendarEventHolder } from './calendareventholder.model';
import {User} from "../user.model";


export class SocialEventRequest {

  draftEvent: CalendarEventHolder;
  attendees: User[];
  mailNotif: boolean;
  slackNotif: boolean;
  slackMessage: string;


}
