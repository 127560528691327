import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToolsBoxService} from '../Services/tools.service';
import {User} from "../Models/user.model";
import {UserService} from "../Services/user.service";
import {FirstLetterPipe} from '../Pipes/firstletter.pipe';

@Component({
  selector: 'reset-pwd-modal',
  templateUrl: 'resetpwdmodal.component.html'
})
export class ResetPasswordModalComponent implements OnInit {

  @Input('currentUser') currentUser: User;
  @Output('onClose') onClose: EventEmitter<any> = new EventEmitter();

  newPassword: String;
  passwordMustBeChanged: boolean = true;


  constructor(private userService: UserService, private toolsBoxService: ToolsBoxService) {
  }

  ngOnInit() {
    this.newPassword = this.toolsBoxService.generateRandomString(8);
  }

  close() {
    this.onClose.next(true);
  }

  resetPassword = (): void => {
    //Call the authenticateService and get an Observable
    this.toolsBoxService.tellActionIsInProgress(true);
    this.currentUser.userPasswordTmp = this.passwordMustBeChanged;
    var $returnSubs = this.userService.resetUserPassword(this.currentUser, this.newPassword)
      .subscribe(
        (data) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          //TODO EKA: Display a notif
          this.close();
        },
        (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          //TODO EKA: Display a notif
          this.close();
        });
  }

  getStyle = (userName: string): String => {
    let firstLetterPipe = new FirstLetterPipe();
    return "user-first-initial-" + firstLetterPipe.transform(userName);
  }

}
